@font-face {
  font-family: "Blair ITC Std";
  src:
    url("BlairITCStd-Light.woff2") format("woff2"),
    url("BlairITCStd-Light.woff") format("woff"),
    url("BlairITCStd-Light.otf") format("opentype");
  font-style: normal;
}

@font-face {
  font-family: "DJ5EscrowText";
  src:
    url("DJ5EscrowText-Roman.woff2") format("woff2"),
    url("DJ5EscrowText-Roman.woff") format("woff"),
    url("DJ5EscrowText-Roman.otf") format("opentype");
  font-style: normal;
}

@font-face {
  font-family: "DJ5EscrowUltra";
  src:
    url("DJ5EscrowUltra-Roman.woff2") format("woff2"),
    url("DJ5EscrowUltra-Roman.woff") format("woff"),
    url("DJ5EscrowUltra-Roman.otf") format("opentype");
  font-style: normal;
}
