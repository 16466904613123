/* Global CSS variables */
:root {
  --color-white: #fff;
  --color-black: #000;
  --color-gray: #808080;
  --color-primary: #faf9f5;
}

html,
body {
  height: 100%;
  background-color: var(--color-white);
  font-family: DJ5EscrowText, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Blair ITC Std, system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  text-transform: uppercase;
  letter-spacing: 0.2rem;
}
