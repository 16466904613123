@import 'styles/_reset.scss';
@import 'styles/_global.scss';
@import 'assets/font/fonts.css';

#root,
.App {
  height: 100%;
}

.App {

}
